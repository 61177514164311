@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "sf-pro-medium";   /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url("./fonts/SF-Pro-Text-Medium.otf") format("truetype");
}

@font-face {
  font-family: "sf-pro-regular";
  src: local("AirbnbCerealLight"),
    url("./fonts/SF-Pro-Text-Regular.otf") format("truetype");
}

@font-face {
  font-family: "BebasNeueRegular";
  src: local("BebasNeueRegular"),
    url("./fonts/BebasNeueRegular.otf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Bebas Neue',
    'Poppins', sans-serif, 'SF-Pro-Text-Medium', 'SF-Pro-Text-Regular', BebasNeueRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}